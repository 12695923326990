import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })

      var proglang = this.props.data.proglang.map(function(proglang){
        proglang.name.toLowerCase();
        return <p key={proglang.name}><em>{proglang.name}</em></p>
      })

      
      var cloud = this.props.data.cloud.map(function(cloud){
        cloud.name.toLowerCase();
        return <p key={cloud.name}><em>{cloud.name}</em></p>
      })

      var cprov = this.props.data.cprov.map(function(cprov){
        cprov.name.toLowerCase();
        return <p key={cprov.name}><em>{cprov.name}</em></p>
      })

      var opsys = this.props.data.opsys.map(function(opsys){
        opsys.name.toLowerCase();
        return <p key={opsys.name}><em>{opsys.name}</em></p>
      })
      var ptwf = this.props.data.ptwf.map(function(ptwf){
        ptwf.name.toLowerCase();
        return <p key={ptwf.name}><em>{ptwf.name}</em></p>
      })

      var vtual = this.props.data.vtual.map(function(vtual){
        vtual.name.toLowerCase();
        return <p key={vtual.name}><em>{vtual.name}</em></p>
      })
      var dbase = this.props.data.dbase.map(function(dbase){
        dbase.name.toLowerCase();
        return <p key={dbase.name}><em>{dbase.name}</em></p>
      })

      var scmtools = this.props.data.scmtools.map(function(scmtools){
        scmtools.name.toLowerCase();
        return <p key={scmtools.name}><em>{scmtools.name}</em></p>
      })

      var vstools = this.props.data.vstools.map(function(vstools){
        vstools.name.toLowerCase();
        return <p key={vstools.name}><em>{vstools.name}</em></p>
      })

      var adobe = this.props.data.adobe.map(function(adobe){
        adobe.name.toLowerCase();
        return <p key={adobe.name}><em>{adobe.name}</em></p>
      })







    }

    return (
      <section id="resume">

        <div className="row education">
          <div className="three columns header-col">
              <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  {education}
                </div>
              </div>
          </div>
        </div>


        <div className="row work">

          <div className="three columns header-col">
              <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col">
            {work}
          </div>
      </div>


      
        <div className="row skill">
          
        <div id="sklz">SKILLS</div>
            <div className="three columns">
            <div className="sklist"><h1><span>Programming Languages</span></h1>
                {proglang}
                </div>

            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Operating Systems</span></h1>
                {opsys}
                </div>
            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Cloud Technologies</span></h1>
                {cloud}
                </div>
            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Cloud Provisioning</span></h1>
                {cprov}
                </div>
            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Project Tracking / Workflow</span></h1>
                {ptwf}
                </div>
            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Version Tools</span></h1>
                {vstools}
                </div>
            </div>

            <div className="three columns">
                <div className="sklist"><h1><span>Database</span></h1>
                {dbase}
                </div>
            </div>

            <div className="three columns ">
                <div className="sklist"><h1><span>Software Configuration Tools</span></h1>
                {scmtools}
                </div>
            </div>

            <div className="three columns ">
                <div className="sklist"><h1><span>Virtualization</span></h1>
                {vtual}
                </div>
            </div>

            <div className="three columns ">
                <div className="sklist"><h1><span>Adobe</span></h1>
                {adobe}
                </div>
            </div>



          </div>
        
          
   </section>
    );
  }
}

export default Resume;
